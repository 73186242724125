import {
  MESSAGE_CODES,
  TASK_TYPES,
  TASK_TYPES_VALUE,
  USER_TYPE_APPROVER,
  USER_TYPE_SYSTEM_ADMINISTRATOR,
  USER_TYPE_USER_ADMINISTRATOR,
} from "core/constants";
import dayjs from "dayjs";
import { CurrentUserModel } from "modules/auth/store/types";
import { timeSheetStatusName } from "modules/dashboard/store/types";
import { computed, inject, ref, watch } from "vue";
import { useStore } from "vuex";
import { v4 as uuidv4 } from "uuid";
import {
  convertTaskIdToType,
  convertTaskIdToVisbleType,
  formatTime,
  getTaskTypId,
} from "../utils";
import { getHoursAndMinutes } from "../utils/time";
import { standardizeTime, checkTimeInit } from "../utils/taskDetail";

interface TimeRange {
  StartTime: number;
  EndTime: number;
}

export default function useTaskDetail(ctx: any) {
  const store = useStore();

  const allowUpdate = ref<boolean>(true);
  const totalHours = ref<number>(0);
  const totalMins = ref<number>(0);
  const displayButtonAddTask = ref<boolean>(true);
  const currentTaskVisibleType = ref<any>(null);
  const oldData = ref();

  const allTimesheetLine = computed(
    () => store.getters["dashboard/timeSheetsLine"]
  );

  const timesheetOverlapCheckingEnable = computed(
    () => store.getters["setting/settings"].TimesheetOverlapCheckingEnable
  );

  const timeSheetHeader = ref<TimeSheetHeader>({
    Shift: false,
    StartDate: dayjs().format("YYYY-MM-DD"),
    TimesheetLines: [],
  });

  const notify = inject("notify", (type: string, message: string) => ({}));
  const currentUser = computed<CurrentUserModel>(
    () => store.getters["auth/currentUser"]
  );

  const taskTypeDisplay = computed(() => {
    const userTaskType = currentUser.value.taskType;
    let taskType = userTaskType;
    const showConfig = {
      rental: false,
      jobCost: false,
      unBilled: false,
    };
    if (taskType === TASK_TYPES_VALUE.All) {
      showConfig.rental = true;
      showConfig.jobCost = true;
      showConfig.unBilled = true;
    } else if (taskType === TASK_TYPES_VALUE.Rental) {
      showConfig.rental = true;
    } else if (taskType === TASK_TYPES_VALUE.JobCost) {
      showConfig.jobCost = true;
      showConfig.unBilled = true;
    }
    return showConfig;
  });

  const defaultTaskHeaderType = computed(() => {
    if (taskTypeDisplay.value.rental) {
      return TASK_TYPES.Rental;
    }
    return TASK_TYPES.JobCost;
  });

  const timesheetLineOptionInitData = {
    isExpend: true,
    type: defaultTaskHeaderType.value,
    hours: 0,
    mins: 0,
    visiblePopover: false,
    isValid: false,
    startTime: {
      hours: 0,
      isAm: true,
      minutes: 0,
      isInit: true,
    },
    endTime: {
      hours: 0,
      isAm: true,
      minutes: 0,
      isInit: true,
    },
  };

  const initTimesheetLine = async () => {
    timeSheetHeader.value.TimesheetLines?.push({
      TaskUuid: uuidv4(),
      Option: {
        ...timesheetLineOptionInitData,
        name: "Task 1"
      },
    });
    timeSheetHeader.value.StartDate = dayjs().format("YYYY-MM-DD");
    timeSheetHeader.value.Shift = false
    await store.dispatch("setting/getSettings").then((setting) => {
      if (
        currentUser.value.defaultStartTime &&
        timeSheetHeader?.value?.TimesheetLines?.[0]?.Option?.startTime
      ) {
        let timeObj
        if (setting.EnableUseDefaultStartTime) {
          timeObj = formatTime(setting.DefaultStartTime);
        } else {
          timeObj = formatTime(currentUser.value.defaultStartTime);
        }
        timeSheetHeader.value.TimesheetLines[0].StartTime =
          timeObj.hours * 60 + timeObj.minutes;
        timeSheetHeader.value.TimesheetLines[0].Option.startTime = {
          ...timeObj,
        }
        timeSheetHeader.value.TimesheetLines[0].Option.startTime.isInit = false
      }
    }).catch((error) => {
      if (
        currentUser.value.defaultStartTime &&
        timeSheetHeader?.value?.TimesheetLines?.[0]?.Option?.startTime
      ) {
        const timeObj = formatTime(currentUser.value.defaultStartTime);
        timeSheetHeader.value.TimesheetLines[0].StartTime =
          timeObj.hours * 60 + timeObj.minutes;
        timeSheetHeader.value.TimesheetLines[0].Option.startTime = {
          ...timeObj,
        }
        timeSheetHeader.value.TimesheetLines[0].Option.startTime.isInit = false
      }
    })
  };

  const funcFindMaxMinTime = () => {
    const arrayList = timeSheetHeader.value.TimesheetLines || [];
    const errors = [];
    const convertList = arrayList.map((item: any) => {
      return item;
    });

    convertList.sort(
      (taskA: any, taskB: any) => taskA.StartTime - taskB.StartTime
    );

    for (const shift of convertList) {
      if (
        !shift?.Option?.startTime?.isInit &&
        !shift?.Option?.endTime?.isInit
      ) {
        if (
          shift &&
          shift.StartTime !== undefined &&
          shift.EndTime !== undefined
        ) {
          const amStartTime = shift?.StartTime >= 0 && shift.StartTime <= 719;
          const pmStartTime = shift.StartTime >= 720 && shift.StartTime <= 1439;
          const amEndTime = shift.EndTime >= 0 && shift.EndTime <= 719;
          const pmEndTime = shift.EndTime >= 720 && shift.EndTime <= 1439;

          if (timeSheetHeader.value.Shift) {
            if (
              (amStartTime && pmEndTime) ||
              (pmStartTime && pmEndTime && shift?.EndTime < shift?.StartTime)
            ) {
              // case 1: shift flag = 'night shift', (startTime = AM time & endTime = PM Time)
              // case 2: ((startTime = PM time & endTime = PM time) & (endTime < startTime))
              errors.push({ dayShift: true });
            }
          } else {
            if (
              (pmStartTime && amEndTime) ||
              (amStartTime && amEndTime && shift?.EndTime < shift?.StartTime)
            ) {
              // case 1: shift flag = 'day shift', (startTime = PM time & endTime = AM Time)
              // case 2: ((startTime = AM time & endTime = AM time) & (endTime < startTime))
              errors.push({ nightShift: true });
            }
          }
        }
      }
    }
    return errors;
  };

  const funcOverLap = () => {
    if(!timesheetOverlapCheckingEnable.value) return
    let hasOverlap = false;
    const timesheetLines = timeSheetHeader?.value?.TimesheetLines ?? [];

    if (timesheetLines.length) {
      const sortedTasks: any = [...timesheetLines].sort(
        (a: any, b: any) => a.StartTime - b.StartTime
      );

      for (let i = 0; i < sortedTasks.length - 1; i++) {
        for (let j = i + 1; j < sortedTasks.length; j++) {
          let isInitStart1 = false;
          let isInitEnd1 = false;
          let isInitStart2 = false;
          let isInitEnd2 = false;

          let start1 =
            (timesheetLines?.[i]?.Option?.startTime?.hours ?? 0) * 60 +
            (timesheetLines?.[i]?.Option?.startTime?.minutes ?? 0);
          if (timesheetLines?.[i]?.Option?.startTime?.hours == 12) {
            if (timesheetLines?.[i]?.Option?.startTime?.isAm) {
              if (timesheetLines?.[i]?.Option?.startTime?.minutes ?? 0 > 0) {
                start1 = start1 - 720;
              } else {
                start1 = 0;
              }
            }
          }
          isInitStart1 = !!timesheetLines?.[i]?.Option?.startTime?.isInit;

          let end1 =
            (timesheetLines?.[i]?.Option?.endTime?.hours ?? 0) * 60 +
            (timesheetLines?.[i]?.Option?.endTime?.minutes ?? 0);
          if (timesheetLines?.[i]?.Option?.endTime?.hours == 12) {
            if (timesheetLines?.[i]?.Option?.endTime?.isAm) {
              if (timesheetLines?.[i]?.Option?.endTime?.minutes ?? 0 > 0) {
                end1 = end1 - 720;
              } else {
                end1 = 0;
              }
            }
          }
          if( end1 < start1 ) {
            end1 = end1 + 1440
          }
          isInitEnd1 = !!timesheetLines?.[i]?.Option?.endTime?.isInit;

          let start2 =
            (timesheetLines?.[j]?.Option?.startTime?.hours ?? 0) * 60 +
            (timesheetLines?.[j]?.Option?.startTime?.minutes ?? 0);
          if (timesheetLines?.[j]?.Option?.startTime?.hours == 12) {
            if (timesheetLines?.[j]?.Option?.startTime?.isAm) {
              if (timesheetLines?.[j]?.Option?.startTime?.minutes ?? 0 > 0) {
                start2 = start2 - 720;
              } else {
                start2 = 0;
              }
            }
          }
          isInitStart2 = !!timesheetLines?.[j]?.Option?.startTime?.isInit;

          let end2 =
            (timesheetLines?.[j]?.Option?.endTime?.hours ?? 0) * 60 +
            (timesheetLines?.[j]?.Option?.endTime?.minutes ?? 0);
          if (timesheetLines?.[j]?.Option?.endTime?.hours == 12) {
            if (timesheetLines?.[j]?.Option?.endTime?.isAm) {
              if (timesheetLines?.[j]?.Option?.endTime?.minutes ?? 0 > 0) {
                end2 = end2 - 720;
              } else {
                end2 = 0;
              }
            }
          }
          if( end2 < start2 ) {
            end2 = end2 + 1440
          }
          isInitEnd2 = !!timesheetLines?.[j]?.Option?.endTime?.isInit;

          const allIsInit =
            !isInitStart1 && !isInitEnd1 && !isInitStart2 && !isInitEnd2;

          if (allIsInit) {
            if (start1 > end1) {
              if (
                (start2 >= 0 && start2 < end1) ||
                (end2 > start1 && end2 <= 24 * 60)
              ) {
                hasOverlap = true;
                break;
              }
            }

            if (start2 > end2) {
              if (
                (start1 >= 0 && start1 < end2) ||
                (end1 > start2 && end1 <= 24 * 60)
              ) {
                hasOverlap = true;
                break;
              }
            }

            if (
              (start1 < end2 && end1 > start2) ||
              (start2 < end1 && end2 > start1) ||
              start1 == start2
            ) {
              hasOverlap = true;
              break;
            }
          }
        }
        if (hasOverlap) {
          break;
        }
      }
    }
    return hasOverlap;
  };

  const funcOverLapLevel = (timeSheetHeader: any): boolean => {
    const checkOverlap = (range1: TimeRange, range2: TimeRange)  => {
      return (
        range1.StartTime < range2.EndTime && range2.StartTime < range1.EndTime
      );
    };

    const checkTimeOverlap = (
      timesheetLines: TimeRange[],
      allTimesheetLines: TimeRange[][]
    ): boolean => {
      const allRanges: TimeRange[] = [];

      timesheetLines.forEach((line) => allRanges.push(line));
      allTimesheetLines.forEach((lines) =>
        lines.forEach((line) => allRanges.push(line))
      );

      allRanges.sort((a, b) => a.StartTime - b.StartTime);

      for (let i = 0; i < allRanges.length - 1; i++) {
        if (checkOverlap(allRanges[i], allRanges[i + 1])) {
          return true;
        }
      }

      return false;
    };

    const overlapExists = checkTimeOverlap(
      timeSheetHeader.value?.TimesheetLines,
      allTimesheetLine.value.map((obj: any) => obj.TimesheetLines)
    );

    return overlapExists
  };

  const calTotalTimeSheet = () => {
    let totalMinuets = 0;
    timeSheetHeader.value.TimesheetLines?.forEach((item) => {
      totalMinuets += item.TimesheetLineMinutes ?? 0;
    });
    totalHours.value = Math.floor(totalMinuets / 60);
    totalMins.value = totalMinuets % 60;
    ctx.emit("changeMaxTimeMultipleTasks", totalMinuets);
    const shiftCheck: any = funcFindMaxMinTime();
    if (shiftCheck.length > 0) {
      if (shiftCheck[0].dayShift) {
        ctx.emit("handleCheckDayShift", true);
      } else {
        ctx.emit("handleCheckDayShift", false);
      }
      if (shiftCheck[0].nightShift) {
        ctx.emit("handleCheckNightShift", true);
      } else {
        ctx.emit("handleCheckNightShift", false);
      }
    } else {
      ctx.emit("handleCheckDayShift", false);
      ctx.emit("handleCheckNightShift", false);
    }
    const isOverlapLevel = funcOverLapLevel(timeSheetHeader);
    ctx.emit("handlePopupOverLapLevel", isOverlapLevel);
  };

  const handleSubmitTaskTime = (data: any) => {
    const task: any = timeSheetHeader.value.TimesheetLines?.find(
      (item) => item.TaskUuid === data.taskId
    );
    if (task) {
      const timesheetLineMinutes = data.hours * 60 + data.mins;
      task.TimesheetLineMinutes = timesheetLineMinutes;
      let startTimeHours = task?.Option?.startTime?.hours ?? 0;

      if (task?.Option?.startTime?.isAm) {
        if (task?.Option?.startTime?.hours == 12) {
          startTimeHours = 0;
        } else {
          startTimeHours = task?.Option?.startTime?.hours ?? 0;
        }
      }

      const startTimeMinutes = task?.Option?.startTime?.minutes ?? 0;
      let startTimeInMinutes = startTimeHours * 60 + startTimeMinutes;
      const endTimeInMinutes = startTimeInMinutes + timesheetLineMinutes;
      let endTimeFinalHour = Math.floor(endTimeInMinutes / 60);
      const endTimeFinalMinutes = endTimeInMinutes - endTimeFinalHour * 60;
      endTimeFinalHour = endTimeFinalHour == 24 ? 24 : endTimeFinalHour % 24;

      let time = {
        hours: Math.abs(endTimeFinalHour) == 24 ? 0 : Math.abs(endTimeFinalHour),
        minutes: Math.abs(endTimeFinalMinutes),
        isAm: endTimeFinalHour == 24 ? true : endTimeFinalHour < 12,
      };
      const startInit = task?.Option?.startTime?.isInit;
      const endInit = task?.Option?.endTime?.isInit;

      // AA, AB, BA, BB
      if (startInit && endInit) {
        task.Option.startTime = {
          hours: 12,
          minutes: 0,
          isAm: true,
          isInit: false,
        };
        task.Option.endTime = {
          ...time,
          isInit: false,
        };
      } else if (startInit && !endInit) {
        let endTimeInMinutes =
          (task?.Option?.endTime?.hours ?? 0) * 60 +
          (task?.Option?.endTime?.minutes ?? 0);

        if (task?.Option?.endTime?.isAm) {
          if (task?.Option?.endTime?.hours == 12) {
            endTimeInMinutes = 1440 + (task?.Option?.endTime?.minutes ?? 0);
          } else {
            endTimeInMinutes =
              (task?.Option?.endTime?.hours ?? 0) * 60 +
              (task?.Option?.endTime?.minutes ?? 0);
          }
        }
        let startTimeInMinutes =
          endTimeInMinutes - (data.hours * 60 + data.mins);
        let hours = Math.floor(startTimeInMinutes / 60);
        let minutes = startTimeInMinutes % 60;
        let isAm = hours < 12;
        if (!isAm && hours !== 12) {
          hours -= 12;
        }
        task.Option.startTime = {
          hours: hours == 0 ? 12 : hours,
          minutes: minutes,
          isAm: isAm,
          isInit: false,
        };
      } else if (!startInit && endInit) {
        task.Option.endTime = {
          ...time,
          isInit: false,
        };
      } else if (!startInit && !endInit) {
        task.Option.endTime = {
          ...time,
          isInit: false,
        };
      }
      task.Option.hours = data.hours;
      task.Option.mins = data.mins;
      const newOption = standardizeTime(task.Option);

      task.StartTime = newOption.startTime;
      task.EndTime = newOption.endTime;

      ctx.emit("changeEndTimePM", time.hours * 60 + time.minutes);
      calTotalTimeSheet();
      const isOverlap = funcOverLap();
      if (isOverlap) {
        ctx.emit("handlePopupOverLap", true);
      } else {
        ctx.emit("handlePopupOverLap", false);
      }
    }
  };

  const updateTaskTime = (
    data: any,
    additionValues: { TaskUuid: string; isStartTime: boolean }
  ) => {
    const timesheetLineMinutes = data.hours * 60 + data.minutes;
    if (additionValues.isStartTime) {
      ctx.emit("changeStartTimeAM", timesheetLineMinutes);
    } else {
      ctx.emit("changeEndTimePM", timesheetLineMinutes);
    }
    const task = timeSheetHeader.value.TimesheetLines?.find(
      (item) => item.TaskUuid === additionValues.TaskUuid
    );
    if (task) {
      if (additionValues.isStartTime) {
        task.Option.startTime = {
          ...data,
          isInit: false,
        };
        task.StartTime = timesheetLineMinutes;
      } else {
        task.Option.endTime = {
          ...data,
          isInit: false,
        };
        task.EndTime = timesheetLineMinutes;
      }

      if (!task.Option.startTime?.isInit && !task.Option.endTime?.isInit) {
        let startTimeHours = task?.Option?.startTime?.hours ?? 0;
        let endTimeHours = task?.Option?.endTime?.hours ?? 0;

        if (task?.Option?.startTime?.isAm) {
          if (task?.Option?.startTime?.hours == 12) {
            startTimeHours = 0;
          } else {
            startTimeHours = task?.Option?.startTime?.hours ?? 0;
          }
        }
        if (task?.Option?.endTime?.isAm) {
          if (task?.Option?.endTime?.hours == 12) {
            endTimeHours = 0;
          } else {
            endTimeHours = task?.Option?.endTime?.hours ?? 0;
          }
        }
        const startTimeMinutes = task?.Option?.startTime?.minutes ?? 0;
        const endTimeMinutes = task?.Option?.endTime?.minutes ?? 0;
        let totalMins = endTimeMinutes - startTimeMinutes;
        let totalHours =
          endTimeHours - startTimeHours - (totalMins < 0 ? 1 : 0);
        totalMins = totalMins < 0 ? 60 - Math.abs(totalMins) : totalMins;
        const totalStartTimeMinutes = startTimeMinutes + startTimeHours * 60;
        const totalEndTimeMinutes = endTimeMinutes + endTimeHours * 60;
        if (totalHours < 0) {
          totalHours += 24;
        }
        task.Option.hours = totalHours;
        task.Option.mins = Math.abs(totalMins);

        task.StartTime = totalStartTimeMinutes;
        task.EndTime = totalEndTimeMinutes;

        task.TimesheetLineMinutes = totalHours * 60 + Math.abs(totalMins);

        calTotalTimeSheet();
        const isOverlap = funcOverLap();
        if (isOverlap) {
          ctx.emit("handlePopupOverLap", true);
        } else {
          ctx.emit("handlePopupOverLap", false);
        }
      } else {
        if (
          task?.Option?.startTime?.isAm &&
          task?.Option?.startTime?.hours == 12
        ) {
          if (task?.Option?.startTime?.minutes == 0) {
            task.StartTime = 0;
          } else {
            task.StartTime =
              task?.Option?.startTime?.hours * 60 +
              task?.Option?.startTime?.minutes -
              720;
          }
        }
      }
    }
  };

  const handleTaskSubmit = (data: any) => {
    const task = timeSheetHeader.value.TimesheetLines?.find(
      (x) => x.TaskUuid === data.taskId
    );

    if (task) {
      task.Option!.isValid = data.valid;

      if (!data.valid) {
        task.Option!.isExpend = true;
        return;
      }
      task.Notes = data.jobDescription;
      task.WorkOrderTaskId = undefined;
      task.JobCostCodeId = undefined;
      task.GlaccountId = undefined;
      if (task.Option!.type == TASK_TYPES.Rental.toString()) {
        task.TimesheetLineType = getTaskTypId(TASK_TYPES.Rental);
        task.WorkOrderTaskId = data.workOrderTaskId
          ? data.workOrderTaskId
          : undefined;
        task.WorkOrderId = data.workOrder ? data.workOrder : undefined;
        task.EquipmentId = data.fleet ? data.fleet : undefined;
        task.SiteId = parseInt(data?.site?.split("-")?.[1] ?? null);
        task.SiteId = task.SiteId ? task.SiteId : undefined;
      }
      if (task.Option!.type == TASK_TYPES.JobCost.toString()) {
        task.TimesheetLineType = getTaskTypId(TASK_TYPES.JobCost);
        task.JobCostCodeId = data.costCode ? data.costCode : undefined;
        task.JobId = data.job ? data.job : undefined;
        task.EmecoSite = data.isEmecoSite;
        if(data.isEmecoSite) {
          task.WorkOrderId = data.workOrder ? data.workOrder : undefined;
          task.EquipmentId = data.fleet ? data.fleet : undefined;
          task.SiteId = parseInt(data?.site?.split("-")?.[1] ?? null);
          task.SiteId = task.SiteId ? task.SiteId : undefined;
        }
      }
      if (task.Option!.type == TASK_TYPES.UnBilled.toString()) {
        task.TimesheetLineType = getTaskTypId(TASK_TYPES.UnBilled);
        task.GlaccountId = data.glEntry;
      }
      if (data.additionInfo) {
        task.TimesheetAdditional = {
          TimesheetAdditionalId: data.TimesheetAdditionalId,
          Manufacturer: data.manufacturer,
          Model: data.model,
          Smureading: data.SMUHour,
          SerialNumber: data.serialNo,
          CustomerName: data.isEmecoSite ? "Emeco" : data.clientName,
          CustomerEmail: data.customerEmail,
          Locations: data.location,
          TravelKms: data.travelKMS,
          Rego: data.vehRegNo,
          WorkerSignature: data.mechanicalAcceptance,
          CustomerSignature: data.customerAcceptance,
          AdditionalInfo: data.fieldServiceNote,
          CustomerAcceptanceName: data.customerAcceptanceName,
        };
      } else {
        task.TimesheetAdditional = null as any;
      }
    }
  };

  const resetTaskData = (taskId: string) => {
    timeSheetHeader.value.TimesheetLines?.map((timesheetLine: any) => {
      if (timesheetLine.TaskUuid === taskId) {
        timesheetLine.SiteId = undefined;
        timesheetLine.EquipmentId = undefined;
        timesheetLine.WorkOrderId = undefined;
        timesheetLine.WorkOrderTask = {};
        timesheetLine.WorkOrderTaskId = undefined;
        timesheetLine.WorkOrderDescription = "";
        timesheetLine.JobCostCodeId = undefined;
        timesheetLine.JobId = undefined;
        timesheetLine.JobCostCode = {};
        timesheetLine.Glaccount = {};
        timesheetLine.GlaccountId = undefined;
        timesheetLine.TimesheetAdditional = null as any;
        timesheetLine.Notes = "";
      }
      return timesheetLine;
    });
  };

  const createTimeSheet = (timeSheetHeader: any) => {
    store.commit("SET_LOADING", true);
    if (!timeSheetHeader.value?.TimesheetLines?.length) {
      store.commit("SET_LOADING", false);
      return;
    }
    timeSheetHeader.value.StartDate = dayjs(
      timeSheetHeader.value.StartDate
    ).format("YYYY-MM-DD");
    const resultCheckTimeInit = checkTimeInit(timeSheetHeader.value)
    timeSheetHeader.value.TimesheetLines = resultCheckTimeInit
    store.commit("SET_LOADING", true);
    store
      .dispatch("dashboard/actCreateTimeSheet", timeSheetHeader.value)
      .then(() => {
        store.commit("auth/SET_IS_VALID", false);
        store.commit("dashboard/SET_LIST_TIME_SHEET", []);
        store.dispatch("dashboard/actLoadTimeSheet"),
          store.dispatch("dashboard/actCountTimeSheetStatus"),
          store.commit("SET_LOADING", false);

        notify("success", MESSAGE_CODES.MSG_SAVE_SUCCESS);
        store.commit("SET_OPEN_POPUP_ADD_TASK", false);
        timeSheetHeader.value = {
          StartDate: dayjs().format("YYYY-MM-DD"),
          TimesheetLines: [
            {
              TaskUuid: uuidv4(),
              Option: {
                name: `Task 1`,
                ...timesheetLineOptionInitData,
              },
            },
          ],
        };
        totalHours.value = 0;
        totalMins.value = 0;
      })
      .catch(() => {
        store.commit("SET_LOADING", false);
        notify("error", MESSAGE_CODES.ERR_SERVER);
      });
  };

  const loadTimeSheetEdit = async (timeSheetHeaderId: number) => {
    store.commit("SET_LOADING", true);
    const resultTimeSheetHeader = await store.dispatch(
      "dashboard/actGetTimeSheet",
      { id: timeSheetHeaderId }
    );
    store.dispatch("setting/getSettings")
    timeSheetHeader.value = {
      ...resultTimeSheetHeader,
      StartDate: dayjs(resultTimeSheetHeader.StartDate).format("YYYY-MM-DD"),
      TimesheetLines: [],
    };
    store.dispatch("dashboard/loadCountTotalTimesheet", {
      date: dayjs(resultTimeSheetHeader.StartDate).format("YYYY-MM-DD"),
      userId: resultTimeSheetHeader.UserId,
      timesheetId: resultTimeSheetHeader.TimesheetHeaderId,
    });
    ctx.emit("changeShift", !!timeSheetHeader.value.Shift);

    timeSheetHeader.value.TimesheetStatusName =
      timeSheetStatusName[
        timeSheetHeader.value
          .TimesheetStatus! as keyof typeof timeSheetStatusName
      ];
    if ([1, 2, 3, 5].includes(timeSheetHeader.value.TimesheetStatus ?? 0)) {
      if (
        timeSheetHeader.value.TimesheetStatus == 1 &&
        (currentUser.value.userType == USER_TYPE_APPROVER ||
          currentUser.value.userType == USER_TYPE_SYSTEM_ADMINISTRATOR) &&
        currentUser.value.userId !== timeSheetHeader.value.UserId
      ) {
        allowUpdate.value = true;
        ctx.emit("changeAllowEdit", true);
      } else {
        allowUpdate.value = false;
        ctx.emit("changeAllowEdit", false);
      }
    } else {
      if (currentUser.value.userId !== timeSheetHeader.value.UserId) {
        allowUpdate.value = false;
        ctx.emit("changeAllowEdit", false);
      } else {
        allowUpdate.value = true;
        ctx.emit("changeAllowEdit", true);
      }
    }
    if (currentUser.value.userType == USER_TYPE_USER_ADMINISTRATOR) {
      allowUpdate.value = false;
    }
    let lineVisibleTypes: number[] = [];
    if (resultTimeSheetHeader.TimesheetLines) {
      let totalMinEdit = 0;
      for (
        let index = 0;
        index < resultTimeSheetHeader.TimesheetLines.length;
        index++
      ) {
        const line = resultTimeSheetHeader.TimesheetLines[index];

        let hourLine = 0;
        let minLine = 0;
        if (line.TimesheetLineMinutes) {
          minLine = line.TimesheetLineMinutes % 60;
          hourLine = Math.floor(line.TimesheetLineMinutes / 60);
          totalMinEdit += line.TimesheetLineMinutes;
        }
        let additionInfo = undefined;
        if (line.TimesheetAdditionals && line.TimesheetAdditionals.length > 0) {
          additionInfo = { ...line.TimesheetAdditionals[0] };
        }
        delete line["TimesheetAdditionals"];
        const timeSheetLine: TimeSheetLine = {
          ...line,
          TaskUuid: uuidv4(),
          Option: {
            isExpend: true,
            name: `Task ${index + 1}`,
            type: convertTaskIdToType(line.TimesheetLineType!),
            hours: hourLine,
            mins: minLine,
            visiblePopover: false,
            startTime: getHoursAndMinutes(
              line.StartTime,
              line.TimesheetLineMinutes
            ),
            endTime: getHoursAndMinutes(
              line.EndTime,
              line.TimesheetLineMinutes
            ),
            isValid: false,
          },
        };
        timeSheetLine.TimesheetAdditional = additionInfo;
        timeSheetHeader.value.TimesheetLines?.push(timeSheetLine);
        const lineVisibleType = convertTaskIdToVisbleType(
          line.TimesheetLineType!
        );
        if (lineVisibleType != null) {
          lineVisibleTypes = [...lineVisibleTypes, lineVisibleType];
        }
      }
      totalHours.value = Math.floor(totalMinEdit / 60);
      totalMins.value = totalMinEdit % 60;
    }

    if (
      currentUser.value.userType == USER_TYPE_APPROVER ||
      currentUser.value.userType == USER_TYPE_SYSTEM_ADMINISTRATOR
    ) {
      if (timeSheetHeader.value.UserId != currentUser.value.userId) {
        displayButtonAddTask.value = false;
      } else {
        if ([1, 2, 3, 5].includes(timeSheetHeader.value.TimesheetStatus ?? 0)) {
          displayButtonAddTask.value = false;
        } else {
          displayButtonAddTask.value = true;
        }
      }
    } else {
      if ([1, 2, 3, 5].includes(timeSheetHeader.value.TimesheetStatus ?? 0)) {
        displayButtonAddTask.value = false;
      } else {
        displayButtonAddTask.value = true;
      }
    }

    calTotalTimeSheet();

    const taskVisibleType =
      lineVisibleTypes.includes(1) && lineVisibleTypes.includes(2)
        ? 0
        : lineVisibleTypes[0];
    currentTaskVisibleType.value =
      taskVisibleType !== resultTimeSheetHeader?.User?.TaskType
        ? 0
        : taskVisibleType;
    store.commit("SET_LOADING", false);
    oldData.value = {
      Id: timeSheetHeader.value.TimesheetHeaderId,
      StartDate: dayjs(timeSheetHeader.value.StartDate).format("YYYY-MM-DD"),
      TimesheetStatus: timeSheetHeader.value.TimesheetStatus,
      Shift: !!timeSheetHeader.value.Shift,
      TimesheetLines: [],
    };
    timeSheetHeader.value.TimesheetLines?.forEach((line: any) => {
      const updateLine = {
        TimesheetLineId: line.TimesheetLineId,
        TimesheetLineMinutes: line.TimesheetLineMinutes,
        TimesheetLineType: line.TimesheetLineType,
        WorkOrderTaskId: line.WorkOrderTaskId ? line.WorkOrderTaskId : null,
        EquipmentId: line.EquipmentId ? line.EquipmentId : null,
        SiteId: line.SiteId ? line.SiteId : null,
        WorkOrderId: line.WorkOrderId ? line.WorkOrderId : null,
        JobId: line.JobId ? line.JobId : null,
        GlaccountId: line.GlaccountId ? line.GlaccountId : null,
        JobCostCodeId: line.JobCostCodeId ? line.JobCostCodeId : null,
        StartTime: line.StartTime ?? 0,
        EndTime: line.EndTime ?? 0,
        Notes: line.Notes,
        TimesheetAdditional: line.TimesheetAdditional
          ? line.TimesheetAdditional
          : undefined,
        Option: line.Option,
      };
      oldData.value.TimesheetLines.push(updateLine);
    });
  };

  const updateTimeSheet = async (timeSheetStatus: number) => {
    store.commit("SET_LOADING", true);
    let result = false;
    if (!timeSheetHeader.value.TimesheetLines?.length) {
      return false;
    }
    const dataUpdate: any = {
      Id: timeSheetHeader.value.TimesheetHeaderId,
      StartDate: dayjs(timeSheetHeader.value.StartDate).format("YYYY-MM-DD"),
      TimesheetStatus: timeSheetStatus,
      Shift: !!timeSheetHeader.value.Shift,
      TimesheetLines: [],
    };

    timeSheetHeader.value.TimesheetLines?.forEach((line: any) => {
      const updateLine = {
        TimesheetLineId: line.TimesheetLineId,
        TimesheetLineMinutes: line.TimesheetLineMinutes,
        TimesheetLineType: line.TimesheetLineType,
        WorkOrderTaskId: line.WorkOrderTaskId ? line.WorkOrderTaskId : null,
        EquipmentId: line.EquipmentId ? line.EquipmentId : null,
        SiteId: line.SiteId ? line.SiteId : null,
        WorkOrderId: line.WorkOrderId ? line.WorkOrderId : null,
        JobId: line.JobId ? line.JobId : null,
        GlaccountId: line.GlaccountId ? line.GlaccountId : null,
        JobCostCodeId: line.JobCostCodeId ? line.JobCostCodeId : null,
        StartTime: line.StartTime ?? 0,
        EndTime: line.EndTime ?? 0,
        Notes: line.Notes,
        TimesheetAdditional: line.TimesheetAdditional
          ? line.TimesheetAdditional
          : undefined,
        Option: line.Option,
      };
      dataUpdate.TimesheetLines.push(updateLine);
    });

    const resultCheckTimeInit = checkTimeInit(timeSheetHeader.value)
    dataUpdate.TimesheetLines = resultCheckTimeInit

    const objBefore = JSON.stringify(oldData.value);
    const objAfter = JSON.stringify(dataUpdate);

    if (objBefore === objAfter) {
      store.commit("SET_LOADING", false);
      return true;
    }

    await store
      .dispatch("dashboard/actUpdateTimeSheet", dataUpdate)
      .then(() => {
        store.commit("SET_LOADING", false);
        store.commit("auth/SET_IS_VALID", false);
        result = true;
      })
      .catch((error) => {
        console.log("error", error.data.value);
        store.commit("SET_LOADING", false);
        if (error.data.value !== "Sequence contains no elements") {
          notify("error", MESSAGE_CODES.ERR_SERVER);
        }
        result = false;
      });
    return result;
  };

  watch(allTimesheetLine, () => {
    const isOverlapLevel = funcOverLapLevel(timeSheetHeader);
    ctx.emit("handlePopupOverLapLevel", isOverlapLevel);
  })


  return {
    totalHours,
    totalMins,
    timeSheetHeader,
    updateTimeSheet,
    loadTimeSheetEdit,
    createTimeSheet,
    funcOverLap,
    funcFindMaxMinTime,
    handleSubmitTaskTime,
    updateTaskTime,
    handleTaskSubmit,
    resetTaskData,
    initTimesheetLine,
    funcOverLapLevel,
    allowUpdate,
    displayButtonAddTask
  };
}
