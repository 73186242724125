import Service from 'core/service'
import { CurrentUserModel } from '../store/types'
import { AxiosError } from 'axios';
export class AuthService extends Service {
  me(): Promise<CurrentUserModel> {
    return this.get('/auth/me')
  }
  login(body: any, options: any): Promise<any> {
    return this.post("/auth/login", body, options);
  }
  forgotPassword(body: any, options: any): Promise<any> {
    return this.post("/auth/forgot-password", body, options);
  }
  verifyCode(body: any, options: any): Promise<any> {
    return this.post("/auth/verify-code", body, options);
  }
  resetPassword(body: any, options: any): Promise<any> {
    return this.post("/auth/new-password", body, options);
  }
  resendCode(body: any, options: any): Promise<any> {
    return this.post("/auth/resend-code", body, options);
  }
}

