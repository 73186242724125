import itemVue from "modules/super/notifications/components/item.vue";
import { RootState } from "store";
import { ActionTree } from "vuex";
import { ProfileService } from "../service";
import { ProfileState } from "./types";
const service = new ProfileService()
export const actions: ActionTree<ProfileState, RootState> = {
  async actGetDropDownSite({ commit }, params: any) {
    let filter = ``
    const active = params?.active
    if (params.filter) {
      filter = filter + `contains(tolower(Location),'${params.filter.toLowerCase()}')`
    }
    const data = await service.dropDownSite('', filter, undefined, params.limit, active);
    return data.value;
  },
  async actGetDropDownFleet({ commit }, params: any) {
    const active = params?.active

    if(params.filter && (params.search !== undefined)){
      const filter = `(Location eq '${params.filter}') and (contains(tolower(Name),'${params.search.toLowerCase()}'))`
      const data = await service.dropDownEquipment('EquipmentId, Location, Name,Description', filter,undefined, params.limit, active);
      return data.value;
    }

    if (params.id) {
      const filter = `EquipmentId eq ${params.id}`
      const data = await service.dropDownEquipment('EquipmentId, Location, Name,Description', filter,undefined, params.limit, active);
      return data.value;
    }

    if (params.filter) {
      const filter = `Location eq '${params.filter}'`
      const data = await service.dropDownEquipment('EquipmentId, Location, Name,Description', filter,undefined, params.limit, active);
      return data.value;
    }

    if (params.search !== undefined) {
      const filter = `(contains(tolower(Name),'${params.search.toLowerCase()}'))`
      const data = await service.dropDownEquipment('EquipmentId, Location, Name,Description', filter,undefined, params.limit, active);
      return data.value;
    }
    return []
  },
  async actGetFleetById({ commit }, params: any) {
    const data = await service.getEquipmentById(
      params.id,
      params.expand
    );
    return data;
  },
  async actGetDropDownWorkOrder({ commit }, params: any) {
    const active = params?.active
    if (params.search == undefined && !params.filter) return []

    let filter = ``;

    if (params.filter) {
      filter = `(EquipmentId eq ${params.filter})`;
    }

    if (params.search !== undefined) {
      filter = `(contains(tolower(Name),'${params.search.toLowerCase()}') or contains(tolower(Description),'${params.search.toLowerCase()}'))`
    }

    if(params.filter && (params.search !== undefined)){
      console.log(`(EquipmentId eq ${params.filter}) and (contains(tolower(Name),'${params.search.toLowerCase()}') or contains(tolower(Description),'${params.search.toLowerCase()}'))`)
      filter = `(EquipmentId eq ${params.filter}) and (contains(tolower(Name),'${params.search.toLowerCase()}') or contains(tolower(Description),'${params.search.toLowerCase()}'))`
    }

    if(params.filter && params.parent =='Site'){
      filter = params.filter
    }
    if(params.filter && params.parent =='Site' && (params.search !== undefined)){
      filter = `(${params.filter}) and (contains(tolower(Name),'${params.search.toLowerCase()}') or contains(tolower(Description),'${params.search.toLowerCase()}'))`
    }
    
    const data = await service.dropDownWorkOrder(
      "WorkOrderId, Name, EquipmentId, Description, Active",
      filter,
      undefined, params.limit,
      active
    );

    return data.value.map((item: any) => {
      return {
        ...item,
        FullName: `${item.Name} ${item.Description}`,
      };
    });
  },
  async actGetWorkOrderById({ commit }, params: any) {
    const data = await service.getWorkOrderById(
      params.id,
      params.expand
    );
    return data;
  },
  async actGetDropDownWorkOrderTask({ commit }, params: any) {
    const active = params?.active
    if (params.search == undefined && !params.filter) return []

    let filter = ``;

    if (params.filter) {
      filter = `WorkOrderId eq ${params.filter}`;
    }

    if (params.search) {
      filter = `(contains(tolower(Name),'${params.search.toLowerCase()}') or contains(tolower(Description),'${params.search.toLowerCase()}'))`;
    }

    if(params.filter && (params.search !== undefined)){
      filter = `(WorkOrderId eq ${params.filter}) and (contains(tolower(Name),'${params.search.toLowerCase()}') or contains(tolower(Description),'${params.search.toLowerCase()}'))`
    }

    if(params.filter && params.parent =='Site'){
      filter = params.filter
    }
    if(params.filter && params.parent =='Site' && (params.search !== undefined)){
      filter = `(${params.filter}) and (contains(tolower(Name),'${params.search.toLowerCase()}') or contains(tolower(Description),'${params.search.toLowerCase()}'))`
    }

    const data = await service.dropDownWorkOrderTask(
      "WorkOrderTaskId, WorkOrderId, Name, Description, Active",
      filter, 
      params.limit,
      active
    );

    return data.value.map((item: any) => {
      return {
        ...item,
        FullName: `${item.Name} ${item.Description}`,
      };
    });
  },
  async actGetWorkOrderTaskById({ commit }, params: any) {
    const data = await service.getWorkOrderTaskById(
      params.id,
      params.expand
    );
    return data;
  },
  async actGetDropDownJob({ commit }, params: any) {
    const active = params?.active
    let filter = null
    let data: any
    if (params.filter) {
      filter = `contains(tolower(Name),'${params.filter.toLowerCase()}') or contains(tolower(Description),'${params.filter.toLowerCase()}')`
    }
    if (params.division && !Array.isArray(params.division)) {
      const filterDivision = `Division eq '${params.division}'`
      filter = filter ? `(${filter}) and ${filterDivision}` : filterDivision
    }
    if (params.division && Array.isArray(params.division)) {
      const conditions = params.division.map((item:any) => `(Division eq '${item}')`);
      const filterDivision = conditions.join(' or ');
      filter = filter ? `(${filter}) ${filterDivision?`and (${filterDivision})`:''}` : filterDivision
    }
    if (params.JobId) {
      filter = `JobId eq ${params.JobId}`
    }
    data = await service.dropDownJob('JobId, Division, Name, Description', filter, params.limit, active);
    return data.value.map((item: any) => {
      return {
        ...item,
        FullName: `${item.Name} ${item.Description} (${item.Division})`
      }
    });
  },
  async actGetDropDownJobCost({ commit }, params: any) {
    const active = params?.active
    let filter = ``;

    if (params.filter) {
      filter = `JobId eq ${params.filter}`
    }

    if (params.search) {
      filter = `(contains(tolower(Name),'${params.search.toLowerCase()}') or contains(tolower(Description),'${params.search.toLowerCase()}'))`
    }

    if(params.filter && (params.search !== undefined)){ 
      filter = `JobId eq ${params.filter} and (contains(tolower(Name),'${params.search.toLowerCase()}') or contains(tolower(Description),'${params.search.toLowerCase()}'))`
    }

    if (params.division && !Array.isArray(params.division)) {
      const filterDivision = `Job/Division eq '${params.division}'`
      filter = filter ? `(${filter}) and ${filterDivision}` : filterDivision
    }

    if (params.division && Array.isArray(params.division)) {
      const conditions = params.division.map((item:any) => `(Job/Division eq '${item}')`);
      const filterDivision = conditions.join(' or ')
      filter = filter ? `(${filter}) ${filterDivision?`and (${filterDivision})`:''}` : filterDivision
    }

    const data = await service.dropDownJobCost('JobCostCodeId, JobId, Name, Description', filter, params.limit, active);
    return data.value.map((item: any) => {
      return {
        ...item,
        FullName: `${item.Name} ${item.Description}`
      }
    });
  },

  async actGetDropDownGLAccount({ commit }, params: any) {
    const active = params?.active
    const data = await service.dropDownGLAccount('GLAccountId, Description', null, active);
    return data.value
  },

  async actGetJobCostCodeById({ commit }, params: any) {
    const expand = `Job`
    const data = await service.getJobCostCodeById(params.id, expand)
    return data
  },

  async actGetGlAccountById({ commit }, params: any) {
    const data = await service.getGlAccountById(params.id)
    return data
  },

  async selectWorkingHour({ commit }, params: any) {
    const data = await service.selectWorkingHour(params);
    return data;
  },

  async selectTopApprover({ commit }, params: any) {
    const data = await service.selectTopApprover(params);
    return data.data.map((item: any) => {
      return {
        ...item,
        total: item.totalApproved,
        totalApprover: data["totalApproved"],
      }
    });
  },

  async updateAvatar({ commit }, params: any) {
    let data: any;
    data = await service.updateAvatar(params);
    return data;
  },

  async forceLogout({ commit }) {
    await service.patchForceLogout();
    return;
  },

};
